<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-overlay :show="loadingState">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <ValidationProvider name="Land & Soil Characteristics Type (En)" vid="name_en" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="name_en"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('VirtualMuseum.configuration.soil_type_en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="name_en"
                                v-model="soilType.name_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Land & Soil Characteristics Type (Bn)" vid="name_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="name_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('VirtualMuseum.configuration.soil_type_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="name_bn"
                                v-model="soilType.name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-3')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                    </b-overlay>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { soilTypeSaveApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getsoilTypeData()
      this.soilType = tmp
      this.soilType.edit_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        soilType: {
            org_id: 0,
            name_en: '',
            name_bn: '',
            edit_id: ''
        }
    }
  },
  methods: {
    getsoilTypeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
        this.loadingState = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      result = await RestApi.postData(virtualMuseumServiceBaseUrl, soilTypeSaveApi, this.soilType)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
            this.loadingState = false
            this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-3')
      } else {
            this.loadingState = false
            this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
